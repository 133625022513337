import showMore from "./components/showMore"
import { setCSSDefaults } from "./components/defaults"
import animate from "./components/animate"
import topnav from "./components/topnav"

const run = () => {
  // topnav and shoMore do not need delays
  // They have their own observer systems
  topnav.run()
  showMore.run()
  // Delay for brief time before starting animations in view
  setTimeout(animate.run, 100)
}

const init = () => {
  // Wait for document to load
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    setTimeout(run, 10)
  } else {
    document.addEventListener("DOMContentLoaded", () => {
      setTimeout(run, 10)
    })
  }
}

console.log(`Powered by __theme_name__ __theme_version__:`, "__theme_website__")

setCSSDefaults()
init()

