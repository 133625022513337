/**
 * Show More Button & Rows
 */

//  This is useful since GHL's built-in show/hide section feature on buttons requires
//  the element to be shown is already hidden in the editor. Hidden elements are hard
//  to edit since they need to be manually unhidden in the layers panel, edited, and
//  then hidden again.
//
//  To use this Show More feature, add a .show-more class to a trigger button and then
//  add the .show-more class to one or more rows beneath the button. On load, the rows
//  will be auto hidden until the button is clicked.

const SEL_BTN_MORE = ".show-more-btn"
const SEL_BTN_LESS = ".show-less-btn"
const SEL_ROWS = ".c-row.show-more"
// Class added to parent of .show-more-btn when button is clicked ("enabled")
const ENABLED_CLASS = "show-more-enabled"
// Added to show more wrappers to hide them before show more button is clicked
const HIDE_CLASS = "show-more-hide"
// Wrapper elements containing .show-more
const WRAPPER_CLASS = "show-more-wrapper"
// Class added to elements when show more is disabled
const DISABLED_CLASS = "show-more-disabled"

type Listener = (this: HTMLElement, event: Event) => void

const addClickListeners = (btnRow: Element, listener: Listener) => {
  btnRow
    .querySelectorAll(`${SEL_BTN_MORE},${SEL_BTN_LESS}`)
    .forEach((btn: Element) => {
      btn.addEventListener("click", listener)
    })
}

const observeAndListen = (
  trigger: HTMLElement,
  btnContainer: HTMLElement,
  moreElems: NodeListOf<HTMLElement>
) => {
  function onClick(this: HTMLElement, event: Event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    // Disconnect the observer since we know clicks are working
    observer.disconnect()

    const enabled = btnContainer.classList.contains(ENABLED_CLASS)
    moreElems.forEach((elem) => {
      elem.classList[enabled ? "add" : "remove"](HIDE_CLASS)
    })
    // Add/remove .show-more-enabled to button container
    // This triggers css to change which buttons are displayed
    btnContainer.classList[enabled ? "remove" : "add"](ENABLED_CLASS)
  }

  if (!trigger.parentNode || !btnContainer) {
    return
  }

  const observer = new MutationObserver((list, obs) => {
    // Trigger removed from DOM
    if (!trigger.parentNode) {
      // Cleanup old listener
      trigger.removeEventListener("click", onClick)
      // Attach new listeners
      addClickListeners(btnContainer, onClick)
    }
  })
  observer.observe(trigger.parentNode, { childList: true })
  addClickListeners(btnContainer, onClick)
}

const run = () => {
  // Get all trigger elements
  document.querySelectorAll<HTMLElement>(SEL_BTN_MORE).forEach((trigger) => {
    // Get the container of the trigger button
    // This container should have both .show-more-btn and .show-less-btn
    const btnContainer = trigger.closest<HTMLElement>("div.inner")

    if (!btnContainer) {
      return
    }

    let moreElems: NodeListOf<HTMLElement>
    // Search the same column as the .show-more-btn for .show-more elements.

    try {
      moreElems = btnContainer.querySelectorAll<HTMLElement>(
        ".c-wrapper:has(.show-more)"
      )
    } catch (err) {
      console.warn("Browser does not support :has query selector")
      btnContainer.classList.add(DISABLED_CLASS)
      // TODO: implement a custom :has query function that gets the parent
      // wrappers of .show-more elements. It's a pain to create a NodeList.
      // One option is to get the IDs of the wrappers and then pass them
      // into document.querySelectorAll.
      return
    }

    // If .show-more elements were not found, go up a level and
    // search for rows with .show-more
    if (moreElems?.length === 0 && btnContainer.parentNode) {
      const parentRow = trigger.closest(".c-row")
      if (parentRow?.parentNode) {
        // Find all sibling rows with the '.show-more' class
        moreElems = parentRow.parentNode.querySelectorAll<HTMLElement>(SEL_ROWS)
      }
    }

    if (moreElems?.length === 0) {
      btnContainer.classList.add(DISABLED_CLASS)
      // TODO: console log warning
    } else {
      // Hide the .show-more elements until the .show-more-btn is clicked
      moreElems.forEach((elem) => {
        elem.classList.add(HIDE_CLASS, WRAPPER_CLASS)
      })
      observeAndListen(trigger, btnContainer, moreElems)
    }
  })
}

export default {
  run,
}
