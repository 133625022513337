/**
 * Animate & Adorn
 */

// Animates elements when scrolled into view.
// When page loads, animation classes are removed from elements
// then added back when scrolled into view.

// TODO: for infinite animations, keep observer active
//    and disable animation when out of view

const SELECTOR = ":is([class*=animate-], [class*=adorn-])"
const PLAY_CLASS = "animate-play"

// List of animation classes partial matches that require parent overflow:clip.
// Prevents side scrolling of the page due to animation or adornment position.
const CLIP_CLASSES = ["animate-fadeIn", "adorn-"]

interface AnimateElem extends HTMLElement {
  dataset: {
    animate: string
  }
}

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const elem = entry.target as AnimateElem
      observer.unobserve(elem)
      elem.classList.add(PLAY_CLASS)
    }
  })
})

const needsClipping = (classList: DOMTokenList) => {
  for (const cls of CLIP_CLASSES) {
    if (classList.value.includes(cls)) {
      return true
    }
  }
  return false
}

const run = () => {
  document
    .querySelectorAll<AnimateElem>(SELECTOR)
    .forEach((elem: HTMLElement) => {
      if (needsClipping(elem.classList) && elem.parentElement) {
        const section = elem.closest(".c-section") as HTMLElement | null
        const clipElem = section ? section : elem.parentElement
        clipElem.style.overflowX = "clip"
      }
      observer.observe(elem)
    })
}

export default {
  run,
}
