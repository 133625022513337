/**
 * TOP NAV & BANNER
 * Add scroll class to sticky topnav if animate-timeline: scroll() is not supported
 */

// TODO: calculate topnav margin-block size and set a variable on banner to
// allow for topnav animation to shrink margin and banner to translate up.

import { debounce } from "../lib/debounce"

const POLYFILL_DELAY = 1000
const TOPNAV_SELECTOR = ".topnav"
const SCROLL_CLASS = "scroll"
const BANNER_SELECTOR = ".notification-banner"
const BANNER_HIDE_CLASS = "banner-hide"
const BANNER_FIXED_CLASS = "banner-fixed"
const TOPNAV_FIXED_CLASS = "topnav-fixed"
const BODY_SELECTOR = "body"
const CONTAINER_SELECTOR = "#preview-container"
let REPOSITIONED = false

const observeResize = (banner: HTMLElement, topnav: HTMLElement) => {
  function onViewportResize(): void {
    positionBanner(banner, topnav, true, true)
  }
  window.addEventListener("resize", debounce(onViewportResize, 200))
}

const observeTopnav = (banner: HTMLElement, topnav: HTMLElement) => {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.attributeName === "style") {
        const pos = getComputedStyle(topnav).getPropertyValue("position")
        if (pos === "fixed") {
          observer.disconnect()
          if (!REPOSITIONED) {
            observeResize(banner, topnav)
            positionBanner(banner, topnav)
          }
        }
      }
    })
  })
  observer.observe(topnav, {
    attributes: true,
    attributeFilter: ["style"],
  })
  return observer
}

const getBlockPadding = (elem: HTMLElement): string => {
  const top = getComputedStyle(elem).getPropertyValue("padding-top")
  const bottom = getComputedStyle(elem).getPropertyValue("padding-bottom")
  const total = [top, bottom].reduce((acc, cur) => {
    return parseInt(cur, 10) + acc
  }, 0)
  return `${total}px`
}

// Position banner when topnav is position:fixed
// When topnav is not fixed, banner CSS handles the positioning
const positionBanner = (
  banner: HTMLElement,
  topnav: HTMLElement,
  smooth = false,
  setTopMargin = true
) => {
  if (!banner || !topnav) {
    return
  }
  // console.log("positionBanner:", banner, topnav)
  // const scrollAnim = CSS.supports("animation-timeline: scroll()")

  const root = document.documentElement
  const navRect = topnav.getBoundingClientRect()
  const bannerRect = banner.getBoundingClientRect()
  const container = document.querySelector(CONTAINER_SELECTOR) as HTMLElement

  if (!container) {
    // Return if container is not avilable, leaving banner in default state
    return
  }
  if (smooth) {
    // Set smooth transition on preview container margin-top change
    container.style.setProperty("transition", "margin-top .3s ease-out")
  }

  // TODO: finish debugging banner not positioning correctly on Chrome when
  // scrolled and resized. Banner partially disappears behind top nav.
  // Probably need to cache the initial padding or height of topnav before scroll animation
  // takes effect. Then on resize, get the topnav height + the original cached
  // padding. The problem is when it calculates the new height but isn't taking
  // the current scroll position (adjustment) into account. It effectively resets
  // the calculations using incorrect starting height & padding.
  banner.classList.add(BANNER_FIXED_CLASS)
  topnav.classList.add(TOPNAV_FIXED_CLASS)

  let offset = navRect.bottom
  let totalHeight = offset

  const bannerPos = getComputedStyle(banner).getPropertyValue("position")
  if (bannerPos === "fixed") {
    if (!banner.classList.contains(BANNER_HIDE_CLASS)) {
      offset += bannerRect.height
      totalHeight += bannerRect.height
    }
    if (banner.nextElementSibling) {
      offset += parseInt(
        getComputedStyle(banner.nextElementSibling).getPropertyValue(
          "margin-top"
        )
      )
    }
  }

  banner.style.setProperty("--topnav-padding", getBlockPadding(topnav))
  root.style.setProperty("--sticky-topnav-bottom", `${navRect.bottom}px`)
  // Set height of top level sticky elements for .sticky classes to use
  // TODO: make sure height is calculated when page first loads in case scolling has
  //  shrunk the padding
  root.style.setProperty("--top-sticky-height", `${totalHeight}px`)

  if (setTopMargin) {
    container.style.setProperty("margin-top", `${Math.round(offset)}px`)
  }

  REPOSITIONED = true
}

const setBanner = (topnav: HTMLElement) => {
  const banner = document.querySelector(BANNER_SELECTOR) as HTMLElement

  if (!banner) {
    return
  }
  if (getComputedStyle(banner).getPropertyValue("display") === "none") {
    return
  }

  // Add listener to close button
  const closeBtn = banner.querySelector(".btn-close")
  closeBtn?.addEventListener("click", () => {
    banner.classList.add(BANNER_HIDE_CLASS)
    // Reposition but don't set top margin of preview container
    positionBanner(banner, topnav, true, false)
  })

  const navPos = getComputedStyle(topnav).getPropertyValue("position")

  if (navPos !== "fixed" || !topnav.parentElement) {
    // Top nav is not YET fixed, observe it in case it changes
    return observeTopnav(banner, topnav)
  }
  // Top nav is fixed
  // Position the banner & setup view port resizing observer
  positionBanner(banner, topnav)
  observeResize(banner, topnav)
  return
}

// Add a hidden div above the topnav to use to observe when it's scrolled
// in and out of view. Toggle the SCROLL_CLASS accordinly to trigger
// the box shadow and any animations defined in CSS.
const scrollPolyfill = (topnav: HTMLElement) => {
  if (!topnav) {
    return
  }
  console.log("__theme_name__: Adding nav scroll polyfill")

  const intercept = document.createElement("div")
  intercept.setAttribute("data-observer-intercept", "")

  // Insert into the body container to prevent any timing issues with vue updating the dom
  const container = document.querySelector(BODY_SELECTOR) as HTMLElement
  container.insertBefore(intercept, container.firstChild)

  const observer = new IntersectionObserver(([entry]) => {
    topnav.classList.toggle(SCROLL_CLASS, !entry?.isIntersecting)
  })
  observer.observe(intercept)
}

// Use polyfill of sorts if browser does not support animation-timeline:scroll() css
const run = () => {
  const topnav = document.querySelector(TOPNAV_SELECTOR) as HTMLElement
  setBanner(topnav)
  if (CSS.supports("animation-timeline:scroll()")) {
    return
  }
  // Delay to allow  time for vue to update topnav
  setTimeout(() => {
    scrollPolyfill(topnav)
  }, POLYFILL_DELAY)
}

export default {
  run,
}
